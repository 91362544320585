

@media (max-width: 900px) {
    .screen {
        padding-left: 10px;
        padding-right: 10px;
    }
    .burger {
        display: flex;
    }
    .sideBarDashboard {
        transform: translateX(-120px) !important;
    }
    .landingMain {
        flex-direction: column;
        margin: 0 30px;
    }
    .landingMain > div:nth-child(2) img {
        width: 70%;
    }
    .landingPage > img:nth-of-type(2) {
        width: 40%;
    }
    .landingMain > div {
        margin-top: 80px;
        width: 100%;
    }
    .dynamicButtons button {
        font-size: 14px;
        width: 85px
    }
    .toolBar div:last-child {
        padding: 0 7px;
    }
    .toolBar > p {
        padding: 0 7px;
    }
    .wordsItem {
        gap: 0;
    }
    .wordsItem > div {
        display: flex;
        flex-direction: column;
    }
    .wordsItem p:first-child {
        font-size: 20px;
    }
    .wordsItem p:last-child {
        font-size: 17px; 
    }
    .boxForSpecialKeys {
        display: none;
    }
    .boxForWord {
        height: 70px;
        margin-top: 20px;
    }
    .boxForButtons {
        margin: 25px 0;
    }
    .boxForInput input {
        height: 60px;
    }
    .landingPage > img:nth-of-type(3) {
        width: 80px;
        bottom: 20px;
        right: 20px;
    }
}

@media (max-width: 400px) {
    .landingPage > img:nth-of-type(2) {
        width: 64%;
    }
    .landingMain > div:nth-child(2) img {
        width: 100%;
    }
    .landingMain > div:nth-child(1) h1 {
        width: 100%;
    }
}
@media (max-width: 600px) {
    .boxForWord, .boxForSpecialKeys, .boxForInput, .boxForInput input,
    .boxForButtons, .boxForMessage {
        width: 100%;
    }
    .boxForSpecialKeys {
        flex-direction: column;
    }
    .boxForSpecialKeys > div:first-child {
        margin: 15px 0;
    }
    .boxToCount {
        padding: 0;
    }
    .boxToToggle > div {
        padding: 25px 0 25px 45px;
    }
    .landingPage > img:nth-of-type(3) {
        width: 40px;
        bottom: 20px;
        right: 20px;
    }
    .toolBar > p {
        flex-grow: 1.7;
    }
}


