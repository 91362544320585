.landingPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    min-height: 1200px;
}


.landingNav {
    height: 80px;
    width: 100%;
    max-width: 1250px;
}

/*-----------------------------*/

.landingMain {
    margin:0 60px;
    max-width: 1250px;
    display: flex;
    margin-top: 80px;
}
.landingButton {
    width: 150px;
    height: 52px;
    color: white;
    background-color: #11A2C1;
    font-size: 20px;
    border: none;
    border-radius: 100px;
    outline: none;
    cursor: pointer;
    margin-top: 40px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
.landingButton a {
    color: white;
    width: 150px;
    height: 52px; 
    display: flex;
    justify-content: center;
    align-items: center;
}
.stats {
    color: #11A2C1;
}
.stats span {
    margin-right: 15px;
    font-size: 20px;
    font-weight: 900;
}
.landingMain > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}
.landingMain > div:nth-child(1) {

}
.landingMain > div:nth-child(1) h1 {
    width: 90%;
    font-size: 60px;
    line-height: 61px;
    font-weight: 900;
    margin-bottom: 40px;
}
.landingMain > div:nth-child(2) {
    align-items: center;
}
.landingMain > div:nth-child(2) img {
    width: 85%;
}

/*------------------------------------*/


.landingPage > img:nth-of-type(1) {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 100%;
}
.landingPage > img:nth-of-type(2) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 22%;
}
.landingPage > img:nth-of-type(3) {
    position: absolute;
    bottom: 40px;
    right: 40px;
    z-index: 1;
    width: 130px;
}