@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;900&display=swap);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 0.15px;
}
body, html {
    min-height: 100%;
}
button {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: transparent;
}
a, h1, h2, h3, h4, h5, h6, p, li, input {
    color: rgb(58, 58, 58);
}
a {
    color: black;
    text-decoration: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.layoutNav {
    height: 100px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 10px 18px 1px rgba(17, 162, 193, 0.13);
    z-index: 3;
    top: 0;
    position: relative;
    display: flex;
    overflow: hidden;
}
.layoutNav main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logo a {
    width: 120px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: #11A2C1;
    font-weight: bold;
    font-size: 18px;
    padding-left: 10px;
    /*background-color: rgba(236, 238, 245, 0.37);*/
}
.logo img {
    width: 80%;
}
.burger {
    display: none;
}
.burgerOpen, .burgerClose {
    cursor: pointer;
    padding-right: 10px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    height: 24px;
}
.burgerOpen p, .burgerClose p {
    width: 30px;
    height: 2px;
    background-color: #11A2C1;
    margin: 3px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.burgerOpen div, .burgerClose div {
    background-color: #63babd1a;
    width: 0px;
    height: 0px;
    transition: 0.088s;
    border-radius: 50%;
    flex-shrink: 0;
    z-index: -1;
}
.burgerOpen:active div, .burgerClose:active div {
    width: 45px;
    height: 45px;
}
.burgerClose p {
    margin: 0;
}
.burgerClose p:first-child {
    position: relative;
    top: 2px;
    transform: rotate(45deg);
    transform-origin: center;
}
.burgerClose p:last-child {
    position: relative;
    transform: rotate(-45deg);
    transform-origin: center;
}

#app > section {
    display: flex;
    position: relative;
}
.sideBar, .sideBarDashboard {
    background-color: rgb(14, 107, 150);
    background: linear-gradient(180deg, #11C197 0%, #11A2C1 23.96%);
    height: auto;
    width: 120px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    transition: transform 0.7s;
    z-index: 0;
    top: 0px;
    height: 100%;
    position: absolute;
    left: 0;
    padding: 0 20px;
}
.sideBar {
    z-index: 3;
}
.sideBarDashboard {
    z-index: 2;
}
.sideBar ul, .sideBarDashboard ul {  
    margin-top: 80px;
    list-style: none;
    padding: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: rgb(255, 255, 255);
    width: 100%;
}
.sideBar ul li, .sideBarDashboard ul li {
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    transition: 0.8s;
    word-break: break-all;
}
.sideBar a, .sideBarDashboard a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.sideBar a div, .sideBarDashboard a div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

/*}
.sideBar ul li:hover p, .sideBarDashboard ul li:hover p {
    color: white !important;    
}
*/
.sideBar ul li a div:hover svg, .sideBarDashboard ul li a div:hover svg{
    stroke: white !important;
}
.sideBar ul li p, .sideBarDashboard ul li p {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    color: white;
    letter-spacing: 0;
}

.sideBar button, .sideBarDashboard button {
    border: none;
    background: transparent;
    color:  #F1F1F1;
    font-size: 20px;
    letter-spacing: 1.5px;
    outline: none;
    cursor: pointer;
    padding: 25px 24px;
}
/*screen*/
.screen {
    width: 100%;
    background-color: #f0f5f5;
    color: black;
    padding-top: 100px;
    min-height: 1200px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.screen > main {
    width: 100%;
    max-width: 714px;
    margin: 0 10px;
}
.rights {
    margin-top: auto;
    margin-bottom: 10px;
}
.toolBar {
    background-color: white;
    width: 100%;
    height: 80px;
    border: 1px solid #D8D8D8;
    border-radius: 50px;
    display: flex;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.toolBar > a {
    flex-grow: 1;
    border-right:  1px solid #D8D8D8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 0;
    cursor: pointer;
}
.toolBar a > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.toolBar div:first-child svg {
    width: 28px;
    transition: 0.4s;
    fill: #808080;
    stroke: #808080;
}
.toolBar div:first-child:hover svg {
    width: 28px;
    fill: #4A4A4A;
    stroke: #4A4A4A;
}
.toolBar div:first-child:active svg {
    width: 28px;
    fill: #808080;
    stroke: #808080;
}

.toolBar > p {
    flex-grow: 2.5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 0;
    font-weight: 900;
    font-size: 16px;
}
.toolBar > div:last-child {
    flex-grow: 1;
    flex-basis: 0;
    border-left:  1px solid #D8D8D8;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dynamicContainer {
    width: 100%;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    /*grid-template-rows: repeat(4, 160px);*/
    grid-gap: 10px;
    grid-auto-flow: row;
 }
.box {
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    background-color: white;
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}
.box h1 {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    text-align: center;
}
.box img {
    width: 40px;
}

.boxParts {
    border: 1px solid #c5c5c5;
    border-radius: 10px;
    background-color: white;
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}
.boxParts h1 {
    font-size: 20px;
    font-weight: 400;
}
.boxParts h2 {
    font-size: 14px;
    font-weight: 400;
    margin-top: 3px;
}
.parts {
    width: 100px;
    height: 100px;
    background-color: white;
}
.dynamicButtons button {
    width: 114px;
    height: 34px;
    color: white;
    background-color: #FC4FEB;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 100px;
    outline: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 10px 18px 1px rgba(17, 162, 193, 13%);
    opacity: 1;
    transition: opacity 0.8s, box-shadow 0.2s, letter-spacing 0.15s;
    transition-timing-function: ease;
}
.dynamicButtons button a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    height: 34px;
    width: 100%;
}
.dynamicButtons button:hover {
    box-shadow: 0px 10px 18px 1px rgba(17, 162, 193, 34%);
}
.dynamicButtons button:active {
    letter-spacing: 1px;
}

/*-----------------------------------------------------*/

.wordsBox {
    background-color: transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.wordsItem {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D8D8D8;
    padding: 20px 18px;
    background-color: #fff;
}
.wordsItem:first-child {
    border-radius: 10px 10px 0 0;
}
.wordsItem > div {
    display: flex;
    flex-direction: row;
}
.wordsItem p {
    font-size: 18px;
}
.wordsItem p:first-child {
    font-weight: bold;
    width: 220px;
}
.wordsItem img {
    width: 18px;
}
.toSpeak {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
}
.toSpeak:hover {
    background-color: rgb(47, 177, 159, 0.14);
}
.toSpeak:active {
    background-color: transparent;
}
.wordWikiInfo {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}
.wordWikiInfo a {
    color: #05e6b1;
    font-family: serif;
    letter-spacing: 0.25px;
}
.wordWikiInfo:hover {
    text-decoration: underline;
}
.chooseExercise {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 400px;
    background-color: transparent;
    margin-top: 30px;
    border-radius: 30px;
}
.exerciseTranslate {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.paginationContainer {
    margin-top: 30px;
    background-color: #fff;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 7px;
    margin-bottom: 210px;
}
.paginationContainer a {
    width: auto;
    height: auto;
}
/*exercise*/
.containerOne {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 1px solid #D8D8D8;
    border-radius: 30px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
}
.boxForWord {
    background-color: transparent;
    width: 70%;
    height: 150px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
}
.boxForWord > div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    position: relative;
    top: 50px
}
.boxForWord h1 {
    font-size: 29px;
    font-weight: 900;
}
.boxForInput {
    width: 100%;
    display: flex;
    justify-content: center;
}
.boxForInput input { 
    height: 70px;
    width: 70%;
    font-size: 22px;

    border-color: rgb(72, 85, 87);
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    border-width: 2px;

    margin-top: 20px;
 }
 .boxToCount {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 45px;
 }
 .boxToToggle {
     width: 100%;
     display: flex;
     justify-content: flex-end;
 }
 .boxToToggle > div {
     color: #000;
     border-radius: 30px;
     padding: 25px 45px;
     cursor: pointer;
     -webkit-user-select: none;
         -ms-user-select: none;
             user-select: none;
 }
.boxToToggle span:first-child {
    margin-right: 5px;
}
.boxToToggle span:last-child {
    margin-left: 5px;
}
.boxForSpecialKeys {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.boxForSpecialKeys > div:first-child span {
    padding: 4px 10px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    margin-right: 7px;
     color: black;
    /*background-color: #11B7C1;*/
    background-color: rgb(228, 228, 228);
    border-radius: 4px;
}
.boxForButtons {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    margin: 25px 0;
}
.boxForButtons button {
    border: none;
    border-radius: 8px;
    padding: 15px 22px;
    font-weight: bold;
    font-size: 14px;
    outline: none;
    cursor: pointer;
}
.boxForButtons button:hover {
    box-shadow: 0px 10px 18px 1px rgba(17, 162, 193, 34%);
}
.boxForButtons button:active {
    box-shadow: 0px 10px 38px 1px rgba(17, 162, 193, 34%);
}
.boxForButtons button:first-child {
    color: white;
    background-color: #11B7C1;
}
.boxForButtons button:last-child {
    color: #11B7C1;
    background-color: transparent;
    margin-left: 10px;
    border: 1px solid #11B7C1;
}
.boxForMessage {
    height: 20px;
    width: 70%;
    margin-top: 10px;
    color: rgb(19, 19, 19);
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.spinnerContainer > div {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: solid 7px #11A2C1;
    border-bottom: solid 7px rgba(17, 161, 193, 0.0);
    animation-name:  round;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes round {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}


/*--------------------------------------------------------*/

.loading {
    display: flex;
}
.loading p {
    width: 5px !important;
    height: 5px;
    border-radius: 50%;
    background-color: rgb(9, 82, 78);
    animation-name: growBeat;
    animation-iteration-count: infinite;
    animation-duration: 0.4s;
    transform-origin: center;
    animation-timing-function: ease;
    animation-direction: alternate;
    animation-fill-mode: forwards;
}

.loading p:nth-child(2) {
   margin: 0 4px;
   animation-delay: 0.2s;
}
.loading p:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes growBeat {
    0% {transform: scale(0.7);}
    100% {transform: scale(1.1);}
}
/*choose container*/
.choices {
    width: 100%;
    min-height: 500px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 50px;
    padding-left: 50px;
    grid-gap: 30px;
    gap: 30px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #D8D8D8;
    border-radius: 30px;
    padding-bottom: 20px;
    padding-left: 30px;
}
.choices > div {
    border: 2px solid;
    width: 145px;
    height: 164px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
}
.choices button {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    padding: 8px 24px;
    border: none;
    font-size: 14px;
    font-weight: 900;
    border-radius: 16px;
    color: white;
}
/*
.choices > div:nth-child(1) {
    border-color: #ff8040;
    box-shadow: 4px 2px 20px  rgba(255,127,64,0.13);
}
*/
.choices > div:nth-child(1) {
    border-color: #6583FE;
    box-shadow: 4px 2px 20px rgba(101, 131, 254, 0.13);
}
.choices > div:nth-child(2) {
    border-color: #54E360;
    box-shadow: 4px 2px 20px rgba(84, 227, 96, 0.23);
}

/*---------------------------------*/
/*
.choices div:nth-child(1) button {
    background-color: #FF7F40;
    margin-top: 25px;
}
*/
.choices div:nth-child(1) button {
    background-color: #6583FE;
    margin-top: 25px;
}
.choices div:nth-child(2) button {
    background-color: #54E360;
    margin-top: 25px;
}
/*multipleChoiceExercise*/
.multipleChoiceExercise {
    margin-top: 30px;
    border-radius: 30px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #D8D8D8;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
}
.multipleChoiceExercise p {
    margin-top: 15px;
    width: 100%;
    max-width: 480px;
    text-align: right;
    padding: 0 40px;

}
.multipleChoices {
    width: 100%;
    display: flex;
    justify-content: center;
}
.multipleChoices ul {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px;
} 
.multipleChoices ul li {
    list-style-type: none;
    border: 2px solid rgb(120, 120, 120);
    width: 100%;
    max-width: 400px;
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 15px;
    margin: 10px 0;
    padding: 0 20px 0 20px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
}
.multipleChoices ul li svg {
    width: 22px;
    height: 22px;
}
.multipleButtons {
    width: 100%;
    max-width: 480px;
    display: flex;
    justify-content: flex-end;
    padding: 0 40px;
    flex-wrap: wrap;
}
.multipleButtons button {
    border: none;
    border-radius: 8px;
    padding: 15px 22px;
    font-weight: bold;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    margin-top: 5px;
}
.multipleButtons button:hover {
    box-shadow: 0px 10px 18px 1px rgba(17, 162, 193, 34%);
}
.multipleButtons button:active {
    box-shadow: 0px 10px 38px 1px rgba(17, 162, 193, 34%);
}
.multipleButtons button:first-child {
    color: white;
    background-color: #11B7C1;
}
.multipleButtons button:last-child {
    color: #11B7C1;
    background-color: transparent;
    margin-left: 10px;
    border: 1px solid #11B7C1;
}

/*____________________info______________*/

.textBox {
    background-color: white;
    width: 100%;
    min-height: 480px;
    border: 1px solid #D8D8D8;
    border-radius: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 20px 25px 100px 25px;
    margin-bottom: 110px;
}
.textBox nav {
    width: 100%;
    border-bottom: solid 1px rgb(223, 223, 223);  
}
.textBox ul {
    list-style-type: none;
    padding: 15px 0px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.textBox ul li {
    padding: 5px 0px;
    cursor: pointer;
    transition: 0.55s;
    color: rgb(138, 138, 138);
    flex-grow: 0;
    flex-basis: 40%;
}
.textBox ul li:hover {
    text-decoration: underline;
    color: black;
}
.textBox h3 {
    font-size: 20px;
    margin: 15px 0 15px 0;
}
.textBox p {
    color: rgb(51, 51, 51);
    font-size: 17px;
    margin-top: 30px;
}
.infoContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-top: 25px;
}
.infoContent h2 {
    margin: 20px 0;
}
.infoContent p {
    margin: 20px 0;
}
.infoContent ol {
    list-style: inside;
    list-style-type: lower-greek;
}
.infoContent hr {
    margin: 0;
    height: 2px;
    background-color: #b6cec8;
    border: none;
    width: 100%;
}
.termsOfUse p {
    margin-top: 30px;
}
.toastBox {
    position: fixed;
    bottom: 20px;
    min-height: 100px;
    right: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 280px;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.toastBox > div:first-child {
    background-color: rgb(59, 59, 59);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.toastBox > div:first-child p {
    color: white;    
    padding: 0 12px;
}
.toastBox > div:last-child {
    background-color: rgb(53, 53, 53);
}
.toastBox > div:first-child button {
    border: none;
    background: transparent;
    width: 40px;
    height: 40px;
    font-size: 22px;
    color: white;
    cursor: pointer;
}
.toastBox > div:first-child button:hover {
    background-color: rgb(41, 41, 41);
}
.toastBox > div:last-child p {  
    padding: 12px;
    font-size: 12px;
    color: rgb(35, 223, 213);
    letter-spacing: 0.9px;
    line-height: 160%;
    font-weight: 300;
    width: 90%;
}
.toastBox hr {
    border: none;
    height: 1px;
    margin: 10px 0;
    background-color: grey;
}
.landingPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    min-height: 1200px;
}


.landingNav {
    height: 80px;
    width: 100%;
    max-width: 1250px;
}

/*-----------------------------*/

.landingMain {
    margin:0 60px;
    max-width: 1250px;
    display: flex;
    margin-top: 80px;
}
.landingButton {
    width: 150px;
    height: 52px;
    color: white;
    background-color: #11A2C1;
    font-size: 20px;
    border: none;
    border-radius: 100px;
    outline: none;
    cursor: pointer;
    margin-top: 40px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
.landingButton a {
    color: white;
    width: 150px;
    height: 52px; 
    display: flex;
    justify-content: center;
    align-items: center;
}
.stats {
    color: #11A2C1;
}
.stats span {
    margin-right: 15px;
    font-size: 20px;
    font-weight: 900;
}
.landingMain > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}
.landingMain > div:nth-child(1) {

}
.landingMain > div:nth-child(1) h1 {
    width: 90%;
    font-size: 60px;
    line-height: 61px;
    font-weight: 900;
    margin-bottom: 40px;
}
.landingMain > div:nth-child(2) {
    align-items: center;
}
.landingMain > div:nth-child(2) img {
    width: 85%;
}

/*------------------------------------*/


.landingPage > img:nth-of-type(1) {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 100%;
}
.landingPage > img:nth-of-type(2) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 22%;
}
.landingPage > img:nth-of-type(3) {
    position: absolute;
    bottom: 40px;
    right: 40px;
    z-index: 1;
    width: 130px;
}


@media (max-width: 900px) {
    .screen {
        padding-left: 10px;
        padding-right: 10px;
    }
    .burger {
        display: flex;
    }
    .sideBarDashboard {
        transform: translateX(-120px) !important;
    }
    .landingMain {
        flex-direction: column;
        margin: 0 30px;
    }
    .landingMain > div:nth-child(2) img {
        width: 70%;
    }
    .landingPage > img:nth-of-type(2) {
        width: 40%;
    }
    .landingMain > div {
        margin-top: 80px;
        width: 100%;
    }
    .dynamicButtons button {
        font-size: 14px;
        width: 85px
    }
    .toolBar div:last-child {
        padding: 0 7px;
    }
    .toolBar > p {
        padding: 0 7px;
    }
    .wordsItem {
        grid-gap: 0;
        gap: 0;
    }
    .wordsItem > div {
        display: flex;
        flex-direction: column;
    }
    .wordsItem p:first-child {
        font-size: 20px;
    }
    .wordsItem p:last-child {
        font-size: 17px; 
    }
    .boxForSpecialKeys {
        display: none;
    }
    .boxForWord {
        height: 70px;
        margin-top: 20px;
    }
    .boxForButtons {
        margin: 25px 0;
    }
    .boxForInput input {
        height: 60px;
    }
    .landingPage > img:nth-of-type(3) {
        width: 80px;
        bottom: 20px;
        right: 20px;
    }
}

@media (max-width: 400px) {
    .landingPage > img:nth-of-type(2) {
        width: 64%;
    }
    .landingMain > div:nth-child(2) img {
        width: 100%;
    }
    .landingMain > div:nth-child(1) h1 {
        width: 100%;
    }
}
@media (max-width: 600px) {
    .boxForWord, .boxForSpecialKeys, .boxForInput, .boxForInput input,
    .boxForButtons, .boxForMessage {
        width: 100%;
    }
    .boxForSpecialKeys {
        flex-direction: column;
    }
    .boxForSpecialKeys > div:first-child {
        margin: 15px 0;
    }
    .boxToCount {
        padding: 0;
    }
    .boxToToggle > div {
        padding: 25px 0 25px 45px;
    }
    .landingPage > img:nth-of-type(3) {
        width: 40px;
        bottom: 20px;
        right: 20px;
    }
    .toolBar > p {
        flex-grow: 1.7;
    }
}



